import { format } from 'date-fns'

// 시간 옵션
const time_options: any = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  hour12: false,
  timeZone: 'Asia/Seoul',
}

const yyyy_mm_dd_options: any = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
}

// 원하는 형식 (Month 영어 약자, June 등)
const custom_format_options: any = {
  day: 'numeric',
  month: 'short', // 월을 영어 약자로 표시 (e.g., Jan, Feb, Mar)
  year: 'numeric',
}

export const createdAt = new Date().toLocaleString('ko-KR', time_options)

// 날짜 변경 함수
export function formatDate(dateString: any) {
  return new Date(dateString).toLocaleString('ko-KR', time_options)
}

export function formatDate_yyyy_mm_dd(dateString: string) {
  try {
    // "yyyy. MM. dd. HH:mm:ss" 형식의 문자열을 '.'으로 분리
    const [year, month, day] = dateString
      .split('.')
      .map((part) => part.trim())
      .map(Number)

    // 날짜 부분이 유효한지 확인
    if (!year || !month || !day || isNaN(year) || isNaN(month) || isNaN(day)) {
      return 'Invalid Date'
    }

    // 월과 일이 한 자리일 경우 두 자리로 맞추기
    const formattedMonth = month < 10 ? `0${month}` : `${month}`
    const formattedDay = day < 10 ? `0${day}` : `${day}`

    // "yyyy.mm.dd" 형식으로 반환
    return `${year}. ${formattedMonth}. ${formattedDay}.`
  } catch (error) {
    return 'Invalid Date'
  }
}

// 새로운 형식 함수 (mm Month yyyy)
export function formatDate_mm_Month_yyyy(dateString: any) {
  return new Date(dateString).toLocaleDateString('en-US', custom_format_options)
}

// html 태그 제거 함수
export function sanitizeHtml(content: string) {
  return content.replace(/<\/?[^>]+(>|$)/g, '')
}

// html 약식 태그 제거 함수

export function sanitizeHtml_small(content: string) {
  return content
    .replace(/<p>\s*(<div[^>]*>[\s\S]*?<\/div>)\s*<\/p>/gi, '$1') // <p><div>...</div></p> 구조를 <div>...</div>로 변경
    .replace(/<p>\s*(<h[1-6][^>]*>[\s\S]*?<\/h[1-6]>)\s*<\/p>/gi, '$1') // <p><h1>...</h1></p> 구조를 <h1>...</h1>로 변경
}
