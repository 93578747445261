import {
  Button,
  Center,
  Flex,
  HStack,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react'

import { useState } from 'react'

interface ModalDialogProps {
  type?: 'alert' | 'success'
  isOpen: boolean
  closeModal: () => void
  iconSrc?: string
  title: string
  descriptions: Array<string>
  btnText?: string
  submitBtnText?: string
  cancelBtnText?: string
  callbackFn?: () => void
}
export default function ModalDialog({
  type,
  isOpen,
  closeModal,
  iconSrc,
  title,
  descriptions,
  btnText,
  submitBtnText,
  cancelBtnText,
  callbackFn,
}: ModalDialogProps) {
  const [callbackRunning, setCallBackRunning] = useState(false)
  return (
    <Modal
      isCentered
      closeOnOverlayClick={true}
      isOpen={isOpen}
      onClose={closeModal}>
      <ModalOverlay />
      <ModalContent
        rounded="20px"
        py={{ base: '1.5rem', sm: '3.75rem' }}
        aspectRatio={3 / 2}
        w="90%"
        maxWidth="39.25rem">
        <ModalBody
          as={Flex}
          justifyContent="center">
          <Stack
            as={Center}
            w={{ base: '100%', sm: '85%' }}
            spacing={{ base: '1rem', sm: '25px' }}>
            <Heading
              fontSize={{ base: '1.25rem', sm: '2rem' }}
              fontFamily={'SuitsBoldFont'}>
              {title}
            </Heading>
            <Stack spacing={'auto'}>
              {descriptions.map((msg, index) => (
                <Text
                  key={index}
                  textAlign={'center'}
                  fontSize={{ base: '3.7vmin', sm: '1rem' }}
                  fontFamily={'SuitsLightFont'}
                  color={'#525252'}>
                  {msg}
                </Text>
              ))}
            </Stack>

            {submitBtnText || cancelBtnText ? (
              <HStack w="full">
                <Button
                  padding="12px 20px"
                  size="lg"
                  w="full"
                  fontSize={{ base: '3.9vmin', sm: '1rem' }}
                  rounded="12px"
                  variant={'outline'}
                  colorScheme="none"
                  onClick={() => {
                    closeModal()
                  }}>
                  {cancelBtnText}
                </Button>
                <Button
                  padding="12px 20px"
                  isLoading={callbackRunning}
                  bg="blackAlpha"
                  size="lg"
                  colorScheme="none"
                  w="full"
                  fontSize={{ base: '3.9vmin', sm: '1rem' }}
                  rounded="12px"
                  onClick={async () => {
                    if (!callbackRunning) {
                      setCallBackRunning(true)
                      if (callbackFn) {
                        await callbackFn()
                      }
                      setCallBackRunning(false)
                      closeModal()
                    }
                  }}>
                  {submitBtnText}
                </Button>
              </HStack>
            ) : (
              <Button
                h={'48px'}
                isLoading={callbackRunning}
                colorScheme="green"
                size="lg"
                w="full"
                fontSize={{ base: '3.9vmin', sm: '1rem' }}
                rounded="12px"
                onClick={async () => {
                  if (!callbackRunning) {
                    setCallBackRunning(true)
                    if (callbackFn) {
                      await callbackFn()
                    }
                    setCallBackRunning(false)
                    closeModal()
                  }
                }}>
                {btnText}
              </Button>
            )}
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
