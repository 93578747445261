'use client'

import { authService, dbService } from '../../../FBase'
import { Button, VStack, useDisclosure } from '@chakra-ui/react'
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth'
import { collection, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore'
import { createdAt } from '../../../lib/util'
import ModalDialog from '../../../components/modal'
import { useEffect, useState } from 'react'
const glassIcon = '/assets/glass-music-icon.png'

export default function Auth() {
  // 최초 로그인 상태
  const [isFirstLogin, setIsFirstLogin] = useState(false)
  const { isOpen, onOpen: openModal, onClose: closeModal } = useDisclosure()

  // 소셜 로그인
  const onSocialClick = async (event: any) => {
    const {
      target: { name },
    } = event
    let provider: any
    if (name === 'google') {
      provider = new GoogleAuthProvider()
    }

    const data = await signInWithPopup(authService, provider)
    const { user } = data
    // 🕰 UTC+9
    const kstOffset = 9 * 60 // KST offset UTC+9
    const lastLoginAtData: any = user?.metadata?.lastSignInTime
    const lastLoginAt = new Date(lastLoginAtData)

    // Convert to KST
    lastLoginAt.setMinutes(lastLoginAt.getMinutes() + kstOffset)

    const userInfoData = {
      displayName: user.displayName,
      email: user.email,
      photoURL: user.photoURL,
      uid: user.uid,
      createdAt: user.metadata.creationTime, // UTC 시간임
      lastLoginAt: lastLoginAt.toUTCString(), // KST 시간임
      updatedAt: createdAt,
    }
    // UserLog Ref를 선언해서, 배열 형태로 회원로그를 저장한다.
    if (userInfoData) {
      const userEmail: any = user?.email
      const userLogCollectionRef: any = collection(dbService, 'UserLog')
      const userLogDocRef = doc(userLogCollectionRef, userEmail)
      const userLogSnapshot = await getDoc(userLogDocRef)

      if (userLogSnapshot.exists()) {
        const userLogData: any = userLogSnapshot.data().userLog || []
        const updatedUserLogData = [...userLogData, userInfoData]

        await updateDoc(userLogDocRef, {
          userLog: updatedUserLogData,
        })
      } else {
        await setDoc(userLogDocRef, {
          userLog: [userInfoData],
        })
      }
      // ✅ 회원 정보를 저장하는 컬렉션
      const userCollectionRef: any = collection(dbService, 'User')
      const userDocRef = doc(userCollectionRef, userEmail)
      const userSnapshot = await getDoc(userDocRef)

      if (userSnapshot.exists()) {
        await updateDoc(userDocRef, {
          ...userInfoData,
          updatedAt: createdAt,
        })
        // 회원 정보가 있으면 홈으로 이동한다.
        window.location.replace('/')
      } else {
        setIsFirstLogin(true)
        const userInitData = {
          displayName: user.displayName,
          email: user.email,
          photoURL: user.photoURL,
          uid: user.uid,
          createdAt: user.metadata.creationTime, // UTC 시간임
          updatedAt: createdAt,
          level: 0,
        }

        await setDoc(userDocRef, {
          ...userInitData,
          updatedAt: createdAt,
        })
      }
    }
  }

  useEffect(() => {
    if (isFirstLogin) {
      console.log('첫 로그인')
      openModal()
    } else {
      console.log('기존 로그인')
    }
  }, [isFirstLogin, openModal])

  //   // 카카오 로그인 다른 방법
  //   const kakao_redirect_uri: any = process.env.REACT_APP_KAKAO_REDIRECT_URI
  //   const kakao_client_id: any = process.env.REACT_APP_KAKAO_REST_API_KEY
  //   const kakaoParams = {
  //     client_id: kakao_client_id,
  //     redirect_uri: kakao_redirect_uri,
  //     response_type: 'code',
  //   }

  //   const params = new URLSearchParams(kakaoParams).toString()

  //   // 네이버로 로그인
  //   const naver_redirect_uri: any = process.env.REACT_APP_NAVER_REDIRECT_URI
  //   const naver_client_id: any = process.env.REACT_APP_NAVER_REST_API_KEY
  //   const naverParams = {
  //     client_id: naver_client_id,
  //     redirect_uri: naver_redirect_uri,
  //     response_type: 'code',
  //     state: 'naver',
  //   }
  //   const naver_param = new URLSearchParams(naverParams).toString()

  return (
    <>
      <VStack spacing={'1rem'}>
        <Button
          onClick={onSocialClick}
          variant={'outline'}
          // colorScheme={'red'}
          borderColor={'#E4E4E4'}
          h={'56px'}
          w="full"
          rounded={'12px'}
          name="google"
          leftIcon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.08 11.2385C21.08 10.494 21.0132 9.77809 20.8891 9.09082H11V13.1524H16.6509C16.4075 14.4649 15.6677 15.577 14.5557 16.3215V18.956H17.9491C19.9345 17.1281 21.08 14.4363 21.08 11.2385Z"
                fill="#4285F4"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.9999 21.5001C13.8349 21.5001 16.2117 20.5599 17.949 18.9563L14.5556 16.3217C13.6153 16.9517 12.4126 17.324 10.9999 17.324C8.26511 17.324 5.95034 15.4769 5.12465 12.9951H1.6167V15.7156C3.34443 19.1472 6.89534 21.5001 10.9999 21.5001Z"
                fill="#34A853"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.12477 12.9946C4.91477 12.3646 4.79545 11.6917 4.79545 10.9996C4.79545 10.3076 4.91477 9.63463 5.12477 9.00463V6.28418H1.61682C0.905682 7.70168 0.5 9.30532 0.5 10.9996C0.5 12.694 0.905682 14.2976 1.61682 15.7151L5.12477 12.9946Z"
                fill="#FBBC05"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.9999 4.67614C12.5415 4.67614 13.9256 5.20591 15.0137 6.24636L18.0253 3.23477C16.2069 1.54045 13.8301 0.5 10.9999 0.5C6.89534 0.5 3.34443 2.85295 1.6167 6.28455L5.12465 9.005C5.95034 6.52318 8.26511 4.67614 10.9999 4.67614Z"
                fill="#EA4335"
              />
            </svg>
          }>
          구글로 계속하기
        </Button>
        {/* <Button
          as={'a'}
          href={`https://kauth.kakao.com/oauth/authorize?${params}`}
          h={'56px'}
          w="full"
          rounded={'12px'}
          colorScheme={'yellow'}
          bg="#FEE500"
          leftIcon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none">
              <path
                fillRule="evenodd"
                clip-rule="evenodd"
                d="M12 19C17.5228 19 22 15.4183 22 11C22 6.58172 17.5228 3 12 3C6.47715 3 2 6.58172 2 11C2 13.899 3.92746 16.4378 6.8131 17.8411L6 21.5C6 21.5 6 22 6.5 22C6.88356 22 9.9152 19.9404 11.3065 18.9811C11.5356 18.9936 11.7669 19 12 19Z"
                fill="black"
              />
            </svg>
          }>
          카카오로 계속하기
        </Button>

        <Button
          as={'a'}
          href={`https://nid.naver.com/oauth2.0/authorize?${naver_param}`}
          h={'56px'}
          w="full"
          rounded={'12px'}
          bg="#03C75A"
          colorScheme={'green'}
          leftIcon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none">
              <path
                d="M13.5614 10.7033L6.14609 0H0V20H6.43861V9.295L13.8539 20H20V0H13.5614V10.7033Z"
                fill="white"
              />
            </svg>
          }>
          네이버로 계속하기
        </Button> */}
      </VStack>

      <ModalDialog
        isOpen={isOpen}
        closeModal={closeModal}
        iconSrc={glassIcon}
        title="회원가입이 완료되었습니다."
        descriptions={[
          '환영합니다. 여름숲 관리자 모드에 오신 것을 환영합니다.',
        ]}
        btnText="홈으로 이동하기"
        callbackFn={() => window.location.replace('/')}
      />
    </>
  )
}
